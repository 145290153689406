.block {
  &--catalog {
    padding: 60px 0 40px;
  }
}

.catalog {
  display: grid;
  grid-template-columns: 264px auto;
  grid-gap: 18px;
  position: relative;

  &.loading {
    .filters__body {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $zindex-modal-backdrop;
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
  }

  @include media-breakpoint-up(md) {
    &.loading {
      .catalog__products-list {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          backdrop-filter: blur(3px);
          top: -1rem;
          left: -1rem;
          right: -1rem;
          bottom: -1rem;
          z-index: $zindex-modal-backdrop;
        }
        /*
        &:after {
          content: '';
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23D40067' viewBox='0 0 512 512'%3E%3Cpath d='M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100px;
          top: calc(50% - 50px);
          left: calc(50% - 50px);
          width: 100px;
          height: 100px;
          z-index: $zindex-modal;
          animation-name: spin;
          animation-duration: 2500ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }
        */
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.loading {
      .catalog__products-list {
        position: relative;
      }
    }
  }

  &__products {
    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 18px;
      grid-row-gap: 20px;

      @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
      }
    }
  }

  &__pagination {
    margin-top: 50px;
  }

  &__filter-open {
    position: sticky;
    bottom: 70px;
    z-index: 10;
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
      padding: 15px 25px 0;
      bottom: 90px;
    }
  }
}

.product-item {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  background-color: #fff;
  color: $text-color;
  min-height: 340px;
  min-width: 170px;
  border-radius: 20px;
  padding: 20px;
  text-decoration: none;
  transition: color .3s;
  box-shadow: #e3e3e3 0 0 5px;

  @include media-breakpoint-down(sm) {
    height: 250px;
    padding: 20px 9px 10px;
    min-height: 250px;
  }

  &:is(a):hover {
    color: #9e1c30;
    text-decoration: none;
  }

  &__image {
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 220px;
    position: relative;

    @include media-breakpoint-down(md) {
      height: 144px;
    }

    picture {
      display: block;
      width: 100%;
      margin: 0;
      transition: opacity 0.25s;
      position: absolute;
    }

    &.with-preview {
      picture:nth-child(2) {
        opacity: 0;
      }
    }
  }

  &__title {
    min-height: 42px;
    max-height: 75px;
    overflow: hidden;
  }

  .is-h5 {
    margin: 0;
  }

  .description {
    display: -webkit-box;
    white-space: pre-wrap;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &.disabled {
    color: #fff;
    background-color: rgba($color-gray, 0.9);
  }

  &:hover {
    .with-preview {
      picture {
        &:nth-child(1) {
          opacity: 0;
        }
        &:nth-child(2) {
          opacity: 1;
        }
      }
    }
  }
}