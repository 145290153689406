.footer {
    padding: 80px 0 60px;
    position: relative;
    flex-shrink: 0;
    background-color: $color-gray;

    @include media-breakpoint-down(md) {
        padding: 40px 0 80px;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 0 30px;

        @include media-breakpoint-down(md) {
            align-items: center;
            justify-content: center;
            padding-bottom: 0;
        }
    }

    &__column {
        @include make-col(4);

        @include media-breakpoint-down(md) {
            @include make-col(12);

            margin-bottom: 60px;
        }

        &:nth-child(1) {
            @include media-breakpoint-up(lg) {
                @include make-col(3);
            }
        }

        &:nth-child(2) {
            @include media-breakpoint-up(lg) {
                @include make-col(5);
            }
        }
    }

    &__bottom {
        padding: 30px 0 0;
        position: relative;
    }

    &__navigation {
        display: flex;
        align-items: center;
    }

    .copyright {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
    }

    .disclaimer {
        font-size: 12px;
        line-height: 16px;
    }

    .logo {
        margin-bottom: 10px;
        margin-right: 10px;

        @include media-breakpoint-down(md) {
            margin: 0 0 50px;
        }
    }

    .list {
        margin-bottom: $default-margin;
    }

    .app-link {
        margin-right: 20px;
    }
}
