button {
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;

    &:disabled {
      cursor: default;
    }
    &:hover,
    &:focus {
        outline: none;
    }
}

.btn {
    font-family: $text-font;
    font-size: 16px;
    line-height: 26px;
    color: $color-white;
    font-weight: 700;
    background-color: $color-red;
    border: 0;
    outline: none;
    border-radius: 20px;
    padding: 7px 20px;
    min-width: 40px;
    min-height: 40px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color .3s, opacity .3s;
    display: inline-flex;
    justify-content: center;
    position: relative;

    &--icon {
        padding: 0;
    }

    &:hover:not(:disabled) {
      text-decoration: none;
    }

    &:disabled {
      opacity: .5;
    }

    &--gray {
        background-color: $text-color;
        color: $color-white;

        &:hover,
        &:focus,
        &:active {
            background-color: darken($text-color, 10%);
        }
    }

    &--white {
        background-color: $color-white;
        color: $color-red;

        &:hover:not(:disabled) {
            color: $color-red;
        }
    }

    &--neutral {
        &:hover {
            background-color: $color-white;
            color: #d40067;
        }
    }

    &--close {
        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            border-radius: 2px;
        }

        &::before {
            width: 14px;
            height: 2px;
        }

        &::after {
            width: 2px;
            height: 14px;
        }
    }

    &--close.btn--white {
        &::after,
        &::before {
            background-color: $color-red;
        }
    }

    &--close.btn--purple {
        &::after,
        &::before {
            background-color: $color-white;
        }

        &:hover {
            background-color: $color-red;
        }
    }

    &--small {
        padding: 0 14px;
        min-height: 24px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }

    &--black {
        &:hover {
            background-color: $color-white;
            color: #d40067;
        }
    }

    &--full {
        width: 100%;
    }

    &--big {
        font-size: 30px;
        line-height: 52px;
    }

    &--popup {
        &:hover {
            background-color: $color-white;
            color: #d40067;
        }
    }
}

.btn-dropdown {
    width: 100%;
    position: relative;
    z-index: 2;

    > .btn {
        width: 100%;
        justify-content: flex-start;
        padding-left: 17px;
        padding-right: 17px;
        position: relative;
        z-index: 2;

        &::after {
            @include icon(updown, $color-black, 7px, 14px);
            position: absolute;
            right: 20px;
            top: 50%;
            width: 7px;
            height: 14px;
            content: '';
            transform: translateY(-50%);
        }
    }

    ul {
        border-radius: 20px;
        background-color: $color-white;
        position: absolute;
        left: -9999px;
        top: 0;
        max-height: 340px;
        overflow: auto;
        padding: 60px 30px 20px;
        width: 100%;

        li + li {
            margin-top: 20px;
        }
    }

    &:hover,
    &:focus {
        .btn {
            box-shadow: none;
        }

        ul {
            left: 0;
        }
    }
}