.spraypaint {
    color: $text-color;
    position: relative;

    &__title {
        margin-bottom: 15px;
        text-align: center;

        @media (max-height: 800px) {
            margin-bottom: 0;

            .is-h2 {
                font-size: 26px;
                line-height: 26px;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: 44px;

        @media (max-height: 800px) {
            margin-top: 10px;
        }
    }

    .is-h2 {
        margin: 0;
    }

    .highlight {
        margin: 0 auto 40px;

        &__image {
            width: 100%;

            img {
                width: 100%;
                height: 400px;
                border-radius: 0;
                object-fit: cover;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;

            &__image {
                margin: 0 auto;

                img {
                    height: 240px;
                }
            }
        }
    }

    .rich-text {
        max-width: 546px;
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
            max-width: calc(100% - 40px) !important;

            p {
                margin-bottom: 10px;
                line-height: 22px;
            }
        }
    }
}
