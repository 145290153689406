form {
    label {
        display: block;

        &:not(.disabled) {
          cursor: pointer;
        }
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        user-select: none;

        &.form-control {
            &:not([size]):not([multiple]) {
                height: auto;
            }
        }
    }

    .help-block {
        margin: 8px 0 0;
    }
}

.form {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-margin - 10px);
}

.form-group {
    position: relative;
    margin: 0 0 10px;

    label {
        margin: 0 0 10px;
    }

    > label {
        line-height: 28px;
        font-weight: 600;
        text-transform: uppercase;
    }

    &.has-error {
        .form-control {
            border-color: $color-red;
        }

        .help-block {
            color: $color-red;
        }
    }
}

.form-control-wrapper {
    position: relative;
}

.form-control {
    color: $text-color;
    font-family: $text-font;
    font-size: 16px;
    line-height: 16px;
    height: 39px;
    border: 0;
    padding: 10px 20px 11px;
    background-color: $color-white;
    border: 1px solid $color-red;
    border-radius: 20px;
    text-transform: none;

    &:focus {
        box-shadow: none;
    }

    &--textarea {
        height: 120px;
    }

    &--select {
        @include icon(select, $color-black, 10px, 15px);
        background-position: center right 10px;
        background-size: 15px;
        padding-right: 35px;
    }

    &--check {
        position: relative;
        padding: 0 0 0 28px;
        margin: 0 0 10px;
        border: 0;
        height: auto;
        border-radius: 0;
        background-color: transparent;

        label {
            margin: 0;
            text-transform: capitalize;

            &.disabled {
                color: $secondary;

                input + i {
                    border-color: $secondary;
                }
            }
        }

        &:last-child {
            margin: 0;
        }

        input[type="radio"],
        input[type="checkbox"] {
            display: none;

            + i {
                position: absolute;
                left: 0;
                top: 0;
                width: 15px;
                height: 15px;
                display: block;
                border: 1px solid #000;
                border-radius: 3px;

                &::before {
                    content: '';
                    display: none;
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    width: 11px;
                    height: 11px;
                    background-color: #000;
                    border-radius: 3px;
                }
            }

            &:checked {
                + i {
                    &::before {
                        display: block;
                    }
                }
            }
        }

        input[type="radio"] {
            + i {
                border-radius: 50%;

                &::before {
                    border-radius: 50%;
                }
            }
        }
    }

    &::-webkit-input-placeholder {
        color: $text-color;
        font-style: normal;
        opacity: 1;
    }

    &::-moz-placeholder {
        color: $text-color;
        font-style: normal;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: $text-color;
        font-style: normal;
        opacity: 1;
    }
}

.form-horizontal {
    .form-group {
        @include make-row();
        margin: 0 0 10px;

        label {
            margin: 0;
        }

        > label {
            @include make-col(3);
            padding: 11px 10px 11px 0;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
                margin: 0 0 10px;
            }

            + .form-control-wrapper {
                padding: 0 0 0 10px;

                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }
        }
    }

    .form-control-wrapper {
        @include make-col(9);

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}

.form-inline {
    flex-direction: row;
    margin-left: -10px;
    margin-right: -10px;

    .form-group {
        flex: 1;
        align-items: normal;
        flex-direction: column;
        padding: 0 10px;
        margin: 0 0 10px;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
        }

        &:last-child:not(:first-child) {
            flex-grow: 0;
            flex-shrink: 0;
        }

        &--error {
            flex: 0 0 100%;
            text-align: center;

            .help-block {
                color: $color-red;
                margin: 0;
            }
        }

        .form-control,
        .help-block {
            width: 100%;
        }

        .bootstrap-select {
            min-width: 100%;

            .dropdown-toggle {
                @extend .form-control;
                @extend .form-control--select;

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                background-color: $color-black,;

                .form-control {
                    background-color: rgba($color-black, .8);
                }

                li {
                    &.disabled {
                        display: none;
                    }

                    .dropdown-item {
                        color: $color-white;

                        &:hover {
                            background-color: $color-red;
                        }
                    }
                }
            }

            > .dropdown-menu {
                border: 1px solid $color-white;
            }
        }
    }
}

.form-search {
    position: relative;

    .form-control {
        width: 100%;
        height: auto;
        padding-right: 55px;
        font-size: 16px;
        line-height: normal;
    }

    .btn {
        &[type="submit"] {
            @include icon(search, $color-red, 20px, 20px);
            position: absolute;
            right: 5px;
            top: 1px;
            display: block;
            height: 45px;

            &:hover,
            &:focus {
                @include icon(search, $color-red, 20px, 20px);
            }
        }
    }
}

// scss-lint:disable ImportantRule
// scss-lint:disable SelectorFormat
.active-campaign-form {
    ._form {
        @extend .form;

        ._form_element {
            @extend .form-group;

            ._field-wrapper {
                input,
                select,
                textarea {
                    @extend .form-control;
                }

                select {
                    @extend .form-control--select;
                }

                textarea {
                    @extend .form-control--textarea;
                    height: 120px !important;
                }
            }
        }

        ._button-wrapper {
            @extend .form-group;

            button {
                @extend .btn;
            }
        }
    }
}

.spotler-form {
    .mpForm {
        @extend .form;

        .mpQuestionTable {
            @extend .form-group;

            &.error {
                input {
                    border-color: $color-red;
                }
            }

            .mpFormLabel,
            .mpFormField {
                display: flex;
                flex-flow: row wrap;
            }

            .mpFormField {
                ul li {
                    position: relative;
                }
            }

            .mpFormLabel {
                label {
                    line-height: 28px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }

            .mpDateField {
                flex-basis: 32.6%;
            }

            input:not([type="submit"]),
            select,
            textarea {
                @extend .form-control;
            }

            input[type="radio"],
            input[type="checkbox"] {
                display: none;

                + label {
                    padding-left: 25px;

                    &::before {
                        display: none;
                        position: absolute;
                        top: 10px;
                        left: 3px;
                        width: 12px;
                        height: 12px;
                        content: '';
                        background-color: $color-white;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 7px;
                        display: block;
                        width: 18px;
                        height: 18px;
                        border: 2px solid $color-white;
                    }
                }

                &:checked {
                    + label::before {
                        display: block;
                    }
                }
            }

            input[type="radio"] {
                + label {
                    &::before,
                    &::after {
                        border-radius: 50%;
                    }
                }
            }

            select {
                @extend .form-control--select;
            }

            textarea {
                @extend .form-control--textarea;
                height: 120px !important;
            }
        }

        .submitButton {
            @extend .btn;
        }

        .mpErrorSummary,
        .mpErrorRow {
            @extend .help-block;
            color: $color-red;
        }
    }
}

.hbspt-form {
    fieldset {
        padding: 0 !important;
        max-width: none !important;
    }

    .hs-main-font-element {
        display: none;
    }

    .hs-form-field {
        @extend .form-group;

        .input {
            margin: 0 !important;
        }
    }

    .form-columns-1 {
        .hs-input {
            width: 100% !important;
        }
    }

    .form-columns-2 {
        margin: 0 -10px;

        .form-group {
            width: 50%;
            padding: 0 10px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .hs-error-msgs {
        .hs-error-msg {
            @extend .help-block;
            color: $color-red !important;
        }
    }

    .no-list,
    .inputs-list {
        margin: 0;
    }

    .hs-input {
        @extend .form-control;

        &.error {
            border-color: $color-red;
        }
    }

    .hs-button {
        @extend .btn;
    }

    .hs-form-booleancheckbox-display {
        &:last-of-type {
            margin: 0;
        }

        input[type="radio"],
        input[type="checkbox"] {
            display: none;

            + span {
                position: relative;
                padding: 0 0 0 25px;
                margin: 0 0 10px;

                &::after {
                    position: absolute;
                    left: 0;
                    top: 5px;
                    display: block;
                    width: 16px;
                    height: 16px;
                    content: '';
                    border: 2px solid $color-white;
                    margin: 0;
                }

                &::before {
                    position: absolute;
                    top: 8px;
                    left: 3px;
                    display: none;
                    width: 10px;
                    height: 10px;
                    content: '';
                    background-color: $color-white;
                }
            }

            &:checked {
                + span {
                    &::before {
                        display: block;
                    }
                }
            }
        }

        input[type="radio"] {
            + span {
                &::after {
                    border-radius: 50%;
                }

                &::before {
                    border-radius: 50%;
                }
            }
        }
    }
}
// scss-lint:enable SelectorFormat
// scss-lint:enable ImportantRule

::-webkit-input-placeholder {
    color: $color-white;
    font-style: normal;
    opacity: 1;
}

::-moz-placeholder {
    color: $color-white;
    font-style: normal;
    opacity: 1;
}

:-ms-input-placeholder {
    color: $color-white;
    font-style: normal;
    opacity: 1;
}

select::-ms-expand {
    display: none;
}

select option[disabled] {
    // display: none;
    color: $secondary;
}

.form-element-suggestions {
    $self: &;
    position: relative;

    .form-search {
        margin-bottom: 0;
    }

    &__content {
        max-height: 80vh;
        overflow-y: auto;

        .wrap {
            background-color: $color-white;
            border-radius: 20px;
            padding: 16px 20px;
            box-shadow: 0 5px 10px #00000029;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        h2 {
            color: $text-color;
            font-size: 16px;
            line-height: 26px;
            font-weight: 700;
            margin: 0 0 14px;
        }

        ul {
            margin: 0;

            li {
                .btn {
                    &--full {
                        justify-content: flex-start;
                    }
                }

                + li {
                    margin-top: 20px;
                }

                &:nth-of-type(n+6) {
                    display: none;
                }
            }
            &.active {
                li {
                    display: block;
                }
            }
        }

        .search-product {
            display: flex;
            align-items: center;
            color: $text-color;

            img {
                width: 45px;
                height: 45px;
                margin-right: 10px;
            }
        }
    }

    &__color {
        display: inline-block;
        width: 25px;
        height: 25px;
        margin: 0 5px;
    }

    &.active {
        #{ $self }__content {
            display: block;
        }
    }

    + .btn {
        z-index: 9999;
    }
}

.show-all {
    color: $color-red;

    width: 100%;
    display: inline-block;
    text-align: center;
    margin-top: 15px;
    cursor: pointer;

    &:hover, &:focus {
        color: darken($color-red, 15%);
    }
}