// FAQ block

.block {
    &--faq {
        // margin: 0 0 $default-block-margin;
        margin: 0;
        padding: 70px 0 10px;
        // background: $color-black;
    }

    &--faq + &--faq {
        margin-top: -$default-block-margin;
    }
}

.faq {
    $self: &;
    display: flex;
    flex-flow: wrap row;
    justify-content: center;
    margin-bottom: $default-block-margin - $default-margin;

    &__title {
        @include make-col(8);
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 60px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding-left: 10px;
            padding-right: 10px;
        }

        + #{ $self }__content {
            margin-top: -($default-margin / 2);
        }

        .is-h4 {
            font-size: 50px;
            line-height: 52px;
            color: $color-red;
            margin: 0;
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 60px;

        @include media-breakpoint-down(md) {
            justify-content: flex-start;
            margin-bottom: 35px;
        }

        .faq-group-link {
            font-size: 16px;
            line-height: 26px;
            color: $text-color;
            font-weight: 600;
            background-color: $color-gray;
            border-radius: 20px;
            padding: 7px 20px;
            text-decoration: none;
        }
    }

    &__content {
        @include make-col(8);
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding-left: 10px;
            padding-right: 10px;
        }

        &--groupped {
            @include make-row();

            .faq-group {
                @include make-col(6);
                padding-left: 30px;
                padding-right: 30px;

                @include media-breakpoint-down(md) {
                    @include make-col(12);

                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .faq-overview {
                margin-bottom: 40px;

                &__item {
                    @include media-breakpoint-up(lg) {
                        @include make-col(12);

                        padding-left: 10px;
                        padding-right: 0;
                    }

                    &:nth-child(n+4) {
                        display: none;
                    }
                }

                &.active {
                    .faq-overview__item {
                        &:nth-child(n+4) {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &__buttons {
        @include make-col(8);
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &--group {
        #{ $self }__title {
            @include make-col(12);
            width: 100%;
            text-align: center;
        }

        #{ $self }__content {
            @include make-col(12);
            width: 100%;
            padding: 0;
            margin: 0 -30px;
            display: flex;
            flex-flow: row wrap;
        }

        #{ $self }__buttons {
            @include make-col(12);
            width: 100%;
            text-align: center;
        }
    }

    .show-all {
        color: $white;
        width: auto;
        font-weight: 600;
        text-transform: uppercase;
        min-width: 260px;
        margin: 10px auto 0;
    }
}

.faq-group {
    .is-h4 {
        font-size: 26px;
        line-height: 32px;
        padding-top: 6px;
        margin-bottom: 30px;
    }

    &--top-5 {
        .is-h4 {
            padding-left: 55px;
            background: url('/assets/img/plastikote/top-5.svg') left center no-repeat;
        }
    }

    &--box {
        .is-h4 {
            padding-left: 55px;
            background: url('/assets/img/plastikote/box.svg') left center no-repeat;
        }
    }

    &--productfinder {
        .is-h4 {
            padding-left: 55px;
            background: url('/assets/img/plastikote/productfinder.svg') left center no-repeat;
        }
    }

    &--post {
        .is-h4 {
            padding-left: 55px;
            background: url('/assets/img/plastikote/post.svg') left center no-repeat;
        }
    }

    &--tag-product {
        .is-h4 {
            padding-left: 55px;
            background: url('/assets/img/plastikote/icons/Product.png') left center no-repeat;
            background-size: 50px;
        }
    }

    &--tag-application {
        .is-h4 {
            padding-left: 55px;
            background: url('/assets/img/plastikote/icons/Application.png') left center no-repeat;
            background-size: 50px;
        }
    }

    &--tag-preparation {
        .is-h4 {
            padding-left: 55px;
            background: url('/assets/img/plastikote/icons/Preparation.png') left center no-repeat;
            background-size: 50px;
        }
    }

    &--tag-surface {
        .is-h4 {
            padding-left: 55px;
            background: url('/assets/img/plastikote/icons/Surfaces.png') left center no-repeat;
            background-size: 50px;
        }
    }

    &--tag-general {
        .is-h4 {
            padding-left: 55px;
            background: url('/assets/img/plastikote/icons/General.png') left center no-repeat;
            background-size: 50px;
        }
    }
}

.faq-overview {
    display: flex;
    flex-wrap: wrap;

    &__item {
        @include make-col(12);
        display: flex;
        flex-direction: column;

        &:last-of-type {
            .faq-question {
                &__title {
                    border-bottom: 0 solid;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            @include make-col(6);
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.faq-question {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    cursor: pointer;
    margin: 0 0 10px;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 35px;
        display: block;
        height: 1px;
        content: '';
        background-color: transparent;
    }

    &__title {
        position: relative;
        display: flex;
        margin: 0;
        padding: 0 40px 10px 0;
        cursor: pointer;
        border-bottom: 1px solid $color-gray;
        min-height: 50px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;

        &::after {
            @include icon(arrow, $color-red, 12px, 12px);
            position: absolute;
            top: -0px;
            right: -10px;
            display: block;
            width: 40px;
            height: 40px;
            content: '';
            transform: rotate(90deg);
            transition: all .3s;

            @include media-breakpoint-down(sm) {
                right: 0;
            }
        }

        .is-h5 {
            font-family: $text-font;
            color: $text-color;
            font-weight: 400;
            text-transform: none;
            margin: 0;
        }
    }

    &__content {
        display: none;
        flex: 1;
        padding: 10px 0 30px 45px;

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        .richtext {
            margin: 0;
        }
    }

    &.active {
        #{ $self } {
            &__title {
                &::after {
                    @include icon(close, $color-red, 12px, 12px);
                }
            }

            &__content {
                display: block;
            }
        }
    }
}
