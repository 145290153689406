.mobile-nav {
  background-color: $color-white;
  color: $color-red;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: flex-end;
  height: 65px;
  padding: 0 0 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);;
  z-index: 100;

  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: space-around;
  }

  &__item {
    position: relative;
    color: $color-red;
    width: 110px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;

    &-icon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      transition: width .3s, height .3s;

      &.search {
        @include icon(search, $color-red, 20px, 20px);
      }

      &.language {
        @include icon(globe, $color-red, 20px, 20px);
      }

      &.wheretobuy {
        @include icon(wheretobuy, $color-red, 20px, 20px);
      }

      &.find {
        @include icon(spray, $color-red, 30px, 30px);
      }
    }

    &.active & {
      &-icon {
        width: 50px;
        height: 50px;
        bottom: 30px;

        &.search {
          @include icon(search, $color-red, 24px, 24px);
        }

        &.language {
          @include icon(globe, $color-red, 24px, 24px);
        }

        &.wheretobuy {
          @include icon(wheretobuy, $color-red, 50px, 50px);
        }

        &.find {
          @include icon(spray, $color-red, 34px, 34px);
        }
      }
    }
  }
}