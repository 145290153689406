.block {
  &--characteristics {
    padding: 60px 0 40px;
    display: flex;
  }
}

.characteristics {
  @include make-row();

  &__content {
    @include make-col(6);

    padding: 0 30px 0 15px;

    @include media-breakpoint-down(md) {
      @include make-col(12);

      padding: 0 15px;
    }
  }

  &__title {
    margin-bottom: 15px;
  }

  .richtext {
    margin-bottom: 15px;

    p {
      margin: 0;
    }
  }

  .download-info {
    @include make-col(6);

    padding: 0 15px 0 30px;

    @include media-breakpoint-down(md) {
      @include make-col(12);

      padding: 0 15px;
    }

    .btn {
      display: inline-block;
      font-family: $heading-font;
      font-size: 26px;
      line-height: 32px;
      background-color: transparent;
      border: 1px solid $color-red;
      border-radius: 30px;
      color: $color-red;
      padding: 13px 20px;
      width: 360px;
      text-align: center;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .btn-dropdown {
      width: 360px;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      ul {
        border: 1px solid $color-red;
        border-radius: 30px;
        padding: 60px 0 5px;

        .btn {
          border: 0;
        }

        li {
          margin: 0;

          .download {
            a {
              color: $color-red;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 5px 15px;
              border-bottom: 1px solid $color-red;

              &:hover,
              &:focus,
              &:active {
                background-color: lighten($color-red, 60%);
              }

              em {
                font-style: normal;
              }
            }
          }

          &:last-child {
            .download {
              a {
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}