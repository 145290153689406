.navigation {
    $self: &;

    ul {
        margin: 0;

        li {
            display: block;
            padding: 0;
            margin: 0;

            a {
                display: block;
                color: $text-color;
                padding: 6px 5px;

                @include media-breakpoint-down(md) {
                    padding: 6px 0;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            > ul {
                display: none;
            }

            &:hover,
            &:focus {
                > a {
                    color: $color-red;
                    text-decoration: none;
                }

                > ul {
                    display: block;
                }
            }

            &.active {
                > a {
                    color: $color-red;
                }
            }

            &.has-subnav {
                position: relative;

                > .toggle-subnav {
                    @include icon(arrow, $text-color, 15px, 14px);
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: none;
                    width: 44px;
                    height: 44px;
                    padding: 0;
                    transform: rotate(90deg);
                    transition: all .3s;

                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                }

                &:hover,
                &:focus {
                    > .toggle-subnav {
                        @include icon(arrow, $color-red, 15px, 14px);
                    }
                }

                &.active {
                    > .toggle-subnav {
                        @include icon(arrow, $color-red, 15px, 14px);
                    }
                }

                &.active,
                &.subnav-active {
                    > .toggle-subnav {
                        transform: rotate(-90deg);
                    }

                    > ul {
                        @include media-breakpoint-down(md) {
                            display: block;
                        }
                    }
                }

                // scss-lint:disable MergeableSelector
                &.active.subnav-active {
                    > .toggle-subnav {
                        @include icon(arrow, $color-red, 14px, 14px);
                        transform: rotate(90deg);
                    }

                    > ul {
                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }
                }
                // scss-lint:enable MergeableSelector
            }
        }
    }

    &--header {
        @include media-breakpoint-down(md) {
            position: absolute;
            left: -100%;
            top: 0;
            width: 100%;
            height: 100vh;
            background: $color-white;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding-top: 110px;
            transition: all .3s ease;
            z-index: 10;
        }

        > ul {
            display: flex;
            padding: 0;
            margin: 0;
            list-style: none;
            font-weight: 400;

            @include media-breakpoint-down(md) {
                display: block;
                padding: 0 40px 70px;
                width: 100%;

                ul {
                    li {
                        a {
                            color: $text-color;
                        }

                        &.active {
                            a {
                                color: $color-red;
                            }
                        }
                    }
                }
            }

            > li + li {
                margin-left: 10px;

                @include media-breakpoint-down(lg) {
                    margin-left: 0;
                }

                @include media-breakpoint-down(md) {
                    margin: 20px 0 0;
                }
            }

            li {
                position: relative;

                a {
                    color: $text-color;
                    font-weight: 600;
                    transition: color .3s;

                    &:hover {
                        color: $color-red;
                    }
                }

                &.has-subnav {
                    position: static;

                    @include media-breakpoint-down(md) {
                        position: relative;
                    }

                    .navigation {
                        left: -99999px;

                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }

                    &:hover,
                    &:focus {
                        > a {
                            text-decoration: underline;
                            position: relative;

                            &::before {
                                position: absolute;
                                top: 0;
                                display: block;
                                width: 100%;
                                height: 50px;
                                content: '';

                                @include media-breakpoint-down(md) {
                                    display: none;
                                }
                            }
                        }

                        .navigation {
                            left: 0;
                            display: flex;

                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                    }

                    &.active,
                    &.subnav-active {
                        > a {
                            &::after {
                                @include icon(close, $text-color, 8px, 8px);
                            }
                        }

                        .navigation {
                            @include media-breakpoint-down(md) {
                                display: block;
                            }
                        }
                    }

                    &.active.subnav-active {
                        .navigation {
                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &--subnav {
        position: absolute;
        top: calc(100% - 20px);
        left: 0;
        flex-wrap: wrap;
        width: 100%;
        padding: 20px 0 0;
        margin: 0;
        z-index: 2;

        @include media-breakpoint-down(md) {
            position: static;
            padding: 0;
        }

        #{ $self }__content {
            flex: 1;
            max-width: 100%;
            padding: 40px;
            margin: 0;
            background-color: $color-gray;

            @include media-breakpoint-down(lg) {
                padding: 40px 20px 40px 20px;
            }

            @include media-breakpoint-down(md) {
                padding: 0;
                max-width: unset;
                margin: 0;
                background-color: transparent;
            }
        }

        #{ $self }__highlight {
            flex: 0 0 645px;
            max-width: 645px;
            background: $color-white;
            position: relative;
            padding: 70px 0 24px;

            @include media-breakpoint-down(lg) {
                flex: 0 0 475px;
                max-width: 475px;
                padding: 30px 0;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            &::after {
                content: '';
                transform: skew(-32deg);
                width: 60%;
                height: 100%;
                background: $color-black;
                position: absolute;
                left: -43%;
                bottom: 0;

                @include media-breakpoint-down(lg) {
                    left: -39%;
                }
            }
        }

        &::before {
            position: absolute;
            top: -15px;
            left: 0;
            display: block;
            width: 100%;
            height: 15px;
            content: '';

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        ul {
            padding: 0;
            display: block;
            column-count: 4;
            column-gap: 30px;
            position: relative;
            z-index: 9;
            margin: 0 -10px;

            @include media-breakpoint-down(md) {
                margin: 0;
                padding: 0 0 0 20px;
                column-count: unset;
            }

            li {
                position: relative;
                width: 100%;
                padding: 0 10px;
                margin: 0;

                @include media-breakpoint-down(md) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0;
                }

                a {
                    display: block;
                    color: $text-color;
                    font-size: 16px;
                    line-height: 21px;
                    text-transform: none;
                    font-weight: 600;
                    padding: 0;

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        line-height: 21px;
                        padding: 3px 0;
                    }
                }

                &:hover,
                &:focus {
                    > a {
                        color: $text-color;
                        text-decoration: underline;
                    }
                }

                &.has-subnav {
                    position: relative;

                    > a {
                        font-weight: 400;
                    }

                    > .toggle-subnav {
                        display: none;

                        @include media-breakpoint-down(md) {
                            display: block;
                        }
                    }
                }

                &.active {
                    > ul {
                        @include media-breakpoint-down(md) {
                            display: block;
                        }
                    }
                }

                ul {
                    display: block;
                    position: static;
                    width: 100%;
                    padding: 0;
                    margin: 0 0 10px;

                    @include media-breakpoint-down(md) {
                        display: none;
                        padding: 0 0 0 20px;
                        margin: 0;
                    }

                    li {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding: 0;

                        a {
                            font-weight: 400;
                        }

                        &.has-subnav {
                            > a {
                                font-weight: 400;
                            }

                            > .toggle-subnav {
                                display: block;
                            }
                        }

                        &.active {
                            > ul {
                                @include media-breakpoint-down(md) {
                                    display: block;
                                }
                            }
                        }

                        ul {
                            display: none;
                            padding: 0 0 0 20px;
                            list-style-type: disc;
                            margin: 0;

                            li {
                                display: list-item;
                            }

                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    &--footer {
        margin-right: 20px;

        ul {
            column-count: 2;
            column-gap: 20px;

            li {
                display: block;
                margin: 0 0 30px;

                a {
                    display: inline;
                    color: $text-color;
                    padding: 0;
                    transition: color .3s;
                }
            }
        }
    }

    &--anchor {
        margin: 0;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -5px;

            li {
                display: inline-block;
                margin: 0 30px;

                a {
                    color: $color-white;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    text-transform: none;
                    text-decoration: none;
                    position: relative;
                    padding: 8px 10px 18px;

                    &::before {
                        content: '';
                        width: 0;
                        height: 4px;
                        background: $color-white;
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transition: all ease .3s;
                        opacity: 0;
                    }
                }

                &:hover,
                &:focus {
                    > a {
                        color: $color-white;
                        text-decoration: none;

                        &::before {
                            opacity: 1;
                            width: 100%;
                            left: 0;
                        }
                    }
                }

                &.active {
                    > a {
                        color: $color-white;

                        &::before {
                            opacity: 1;
                            width: 100%;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}

.primary-navigation-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(md) {
                overflow: auto;
                left: 0;
            }
        }
    }

    body {
        overflow: hidden;

        .header {
            .nav-toggle {
                span,
                &::before,
                &::after {
                    background-color: $color-red;
                }
            }
        }
    }
}

.primary-search-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(md) {
                top: 79px;
            }
        }
    }
}

/*
    body:has(.hero-main) {
        .navigation {
            &--header {
                > ul {
                    > li {
                        > a {
                            @include media-breakpoint-up(lg) {
                                color: $color-white;

                                &:hover {
                                    color: $color-white;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
*/
