.block {
  &--instruction {
    padding: 70px 0 70px;
    display: flex;
    background: $color-gray;

    + .section {
      .block--creative {
        background: transparent;
      }
    }
  }
}

.instruction {
  &__title {
    text-align: center;
    margin-bottom: 72px;
    overflow: hidden;
  }

  &__list {
    counter-reset: counter;
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 46px;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    counter-increment: counter;
    display: flex;
    position: relative;

    &::before {
      content: counter(counter);
      background-color: $color-white;
      position: absolute;
      top: -30px;
      left: 90px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      font-family: $heading-font;
      font-size: 32px;
      line-height: 62px;
      color: $color-red;
      border-radius: 50%;

      @include media-breakpoint-down(md) {
        left: 80px;
      }
    }

    &-image {
      width: 120px;
      height: 120px;
      margin-right: 35px;

      @include media-breakpoint-down(md) {
        margin: 0 auto;
      }
    }

    &-text {
      padding-top: 16px;
      flex: 1;

      @include media-breakpoint-down(md) {
        padding: 0 0 0 30px;
      }
    }
  }
}
