.gallery {
  min-width: 0;
  min-width: 100%;

  picture {
    img {
      border-radius: 8px;
    }
  }

  &__top {
    background-color: $color-white;
    border-radius: 20px;
    margin-bottom: 15px;

    .swiper-slide {
      padding: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-gray;
      border-radius: 20px;

      @include media-breakpoint-down(md) {
        padding: 20px;
      }
    }

    .video {
      display: flex;
      align-items: center;

      &::before {
        width: 60px;
        height: 60px;

        @include media-breakpoint-down(md) {
          width: 34px;
          height: 34px;
        }
      }
    }
  }

  &__thumbnail {
    position: relative;

    @include media-breakpoint-down(xs) {
        overflow: hidden;
    }

    .swiper {
      @include media-breakpoint-down(xs) {
        overflow: visible;
      }

      &::after,
      &::before {
        position: absolute;
        top: 0;
        height: 100%;
        width: 20px;
        z-index: 2;

        @include media-breakpoint-down(xs) {
          content: '';
        }
      }
    }

    .swiper-slide {
      background-color: $color-gray;
      width: 76px;
      height: 76px;
      cursor: pointer;
      position: relative;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.swiper-slide-thumb-active {
        border: 2px solid $color-red;
      }
    }

    .video {
      &::before {
        width: 30px;
        height: 30px;
      }
    }

    .swiper-navigation {
        margin-top: 16px;

        @include media-breakpoint-up(sm) {
            position: absolute !important;
            left: -8% !important;
            width: 116%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            margin: 0;
        }
    }
  }

  .video {
    position: relative;

    &::before {
      @include icon(play, $color-white, 50px, 33px);
      content: '';
      background-size: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      box-shadow: 0 0 10px #0000005A;
      border-radius: 50%;
    }

    &:hover {
      &::before {
        @include icon(play, $color-red, 33px, 33px);
      }
    }
  }

  #link-360 {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
  }
}
