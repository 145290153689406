.block {
  &--products {
    overflow: hidden;
  }
}

.products {
  &__title {
    position: relative;
    text-align: center;
    margin-bottom: 54px;
    color: $text-color;
    z-index: 5;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
  }
}

html :not(.fp-enabled) .block {
  &--products, &--about, &--howtos {
    padding: 60px 0 30px;

    @include media-breakpoint-down(md) {
      padding-top: 20px;
    }
  }

  &--spraypaint {
    padding: 80px 0;

    @include media-breakpoint-down(md) {
      padding: 40px 0;
    }
  }

  &--advantages {
    padding: 40px 0 100px 0;
    background: $color-white;
  }
}

.product {
  display: block;
  text-decoration: none;
  transition: color .3s;

  &__image {
    background-color: $color-gray;
    border-radius: 20px;
    padding: 10px 5px;
    margin-bottom: 20px;
    text-align: center;
    height: 245px;

    @include media-breakpoint-down(sm) {
      padding: 14px 10px 26px;
    }

    picture {
      margin: 0;
      height: 100%;

      img {
        width: auto;
        height: 100%;
      }
    }
  }

  &__content {
    color: $text-color;
  }

  .is-h4 {
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    color: $text-color;
    transition: color .3s;
    margin: 0;

    @include media-breakpoint-down(sm) {
      min-height: 75px;
    }
  }

  &:hover {
    text-decoration: none;

    .is-h4 {
      opacity: 0.6;
    }
  }
}

.home .product .is-h4 {
  @include media-breakpoint-down(sm) {
    min-height: 0 !important;
  }
}

.products-slider {
  padding-bottom: 30px;

  @include media-breakpoint-down(sm) {
    padding-bottom: 15px;
  }

  &__item {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 9px;

    @include media-breakpoint-down(md) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include media-breakpoint-down(sm) {
      flex: 0 0 61%;
      max-width: 61%;
    }

    @include media-breakpoint-up(xl) {
      max-width: 15%;
    }
  }
}

.swiper {
  &--products-slider {
    overflow: initial;
    
    .swiper-navigation {
      position: absolute;
      z-index: 1;
      right: 20px;
      bottom: 20px;
      left: auto;
      width: auto;
    }
  }
}

.block {
  &--products {
    .wrapper {
      @include media-breakpoint-up(xl) {
        max-width: unset;
      }
    }
  }
}