.highlights {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-block-margin - $default-margin);

    @include media-breakpoint-down(sm) {
        margin: 0 -15px;
    }

    &__title {
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        text-align: center;
    }
}

.highlight {
    $self: &;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: $default-block-margin - $default-margin;

    &:last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    .date-label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
    }

    &__image {
        @include make-col(6);
        margin-bottom: $default-margin;
        position: relative;

        img {
            border-radius: 20px;
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);

            img {
                max-height: 350px;
            }
        }
    }

    &__content {
        @include make-col(6);
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0 55px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .richtext {
            margin: 0 0 30px;

            + .read-more {
                margin-top: -30px;
            }
        }
    }

    &--reversed {
        flex-direction: row-reverse;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        #{ $self }__content {
            padding: 0 50px;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
            }
        }
    }

    &--hero {
        @include make-row();
        align-items: initial;
        margin: 0 -30px ($default-block-margin - $default-margin);

        @include media-breakpoint-down(md) {
            margin: 0 20px ($default-block-margin - $default-margin);
        }

        #{ $self }__image {
            @include make-col(7);
            position: relative;
            overflow: hidden;
            margin: 0 auto 0 0;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            order: 1;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            picture {
                height: 100%;
                width: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 0;
                }
            }
        }

        #{ $self }__content {
            @include make-col(5);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px 90px 0 110px;
            background: $color-red;
            margin: 0 0 0 auto;
            order: 2;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;

            @include media-breakpoint-down(md) {
                @include make-col(6);
                padding: 20px 15px 0 30px;
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 30px 40px 20px;
            }

            .is-h2 {
                color: $color-white;
                margin-bottom: 15px;

                + .richtext {
                    margin-top: -($default-margin / 2);
                }
            }

            .richtext {
                color: $color-white;
                margin-bottom: 10px;

                + p {
                    margin-top: -($default-margin / 2);
                }
            }

            .btn {
                background-color: $color-white;
                color: $color-red;
            }
        }

        &#{ $self }--reversed {
            #{ $self }__image {
                order: 1;
                margin: 0 0 0 auto;

                &::after {
                    left: -680px;
                    right: auto;
                    top: auto;
                    bottom: 0;
                }

                a {
                    &.video {
                        &::after {
                            left: 65%;
                        }
                    }
                }
            }

            #{ $self }__content {
                padding: 20px 10px 0 80px;
                order: 2;
                margin: 0 auto 0 0;

                @include media-breakpoint-down(md) {
                    padding: 20px 0 0 15px;
                }

                @include media-breakpoint-down(sm) {
                    padding: 30px 30px 0;
                }
            }
        }
    }

    &--video {
        flex-direction: column;

        #{ $self }__image {
            @include make-col(12);
            margin: 0;

            .video {
                &::before {
                    @include icon(play, $color-white, 60px, 60px);
                    content: '';
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &--text {
        align-items: initial;
        flex-direction: column;

        #{ $self }__content {
            @include make-col(12);
            padding: 0;
        }
    }
}

.dupli-rt1c .highlights {
    padding: 0 30px;

    .highlight__image {

        @include media-breakpoint-down(sm) {

            img {
                max-height: 100%;
            }
        }
    }
}


.highlights-overview {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;

    &__item {
        @include make-col(6);
        padding: 0 30px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    &--row {
        #{ $self }__item {
            @include make-col(12);
        }
    }

    &--three-columns {
        #{ $self }__item {
            @include make-col(4);

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    &--stores {
        margin: 0 -15px;

        #{ $self }__item {
            @include make-col(4);
            padding: 0 10px;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    &--two-columns,
    &--three-columns {
        #{ $self }__item {
            margin: 0 0 ($default-block-margin - $default-margin);

            .highlight:not(.highlight--small) {
                height: 100%;
                margin: 0;

                .richtext {
                    + p {
                        margin-top: auto;
                    }
                }
            }
        }
    }
}

html :not(.fp-enabled) .block--highlights {
    margin: 0;
    padding: 40px 0;
}


