.block {
    &--where-to-buy {
        padding: 80px 0;
    }
}

.where-to-buy {
    @include make-row();
    align-items: center;
    justify-content: center;
    padding-top: 40px;

    .store-logo {
        @include make-col(2);
        display: flex;
        justify-content: center;
        padding: 0 15px;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
            @include make-col(4);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(6);
        }

        a {
            display: block;
        }

        img {
            width: auto;
            height: auto;
            max-height: 60px;
        }
    }
}
