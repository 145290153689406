.tooltip {
  display: inline-flex;
  background-color: $color-red;
  color: $color-white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  font-size: 12px;
  line-height: 10px;
  justify-content: center;
  font-weight: bold;
  padding: 0;
  margin: 0;
  cursor: pointer;
}