.blocktheme {
    &--red {
        background-color: $color-red;

        /**.hero-main:not(.hero-main--video):not(.hero-main--regular) {
            &::before {
                z-index: 2;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                clip-path: polygon(0 0, 49% 0, 59% 72%, 0 100%);
                background-color: $color-red;

                @include media-breakpoint-down(md) {
                clip-path: polygon(0 0, 65% 0, 70% 47%, 0 80%);
                }

                @include media-breakpoint-down(sm) {
                clip-path: polygon(0 0, 65% 0, 91% 47%, 0 59%);
                }
            }
        }**/

        .advantages *, .products__slider a * {
            color: darken($color-red, 10%);
        }

        .btn {
            background-color: darken($color-red, 10%);
        }
    }
}
