$color-black-bg: #d3d3d3;
$text-colorer: #717171;
$color-text: #141215;

#dupli-colorguide {
  @import "bootstrap/scss/grid";
  @import "bootstrap/scss/utilities";

  .error {
    padding: 2rem;
    text-align: center;
  }

  .overflow-wrapper {
    width: 100%;
    padding: 20px;

    @include media-breakpoint-between(md, lg) {
      padding-left: 10px;
      padding-right: 10px;
      overflow: auto hidden;
    }
  }
}

.color-guide-overview {
  $self: &;
  @include make-row();
  margin: 0 -8px ($default-margin - 20px);

  &__item {
    @include make-col(4);
    padding: 0 8px;

    @include media-breakpoint-down(md) {
      @include make-col(6);
    }

    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }

    .color-guide-color {
      margin: 0 0 20px;
      height: calc(100% - 20px);
    }
  }
}

.color-guide-color {
  $self: &;
  display: flex;
  flex-direction: column;
  margin: 0 0 $default-margin;
  padding: 0 0 0 55%;

  &__content {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0;
    height: 100%;
    min-height: 100px;

    .is-h3,
    .is-h4 {
      margin: 0 0 15px;

      + .is-h3,
      + .is-h4 {
        margin-top: -15px;
      }
    }

    .is-h3 {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }

    .is-h4 {
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.color-guide-table {
  display: block;
  background-color: $color-white;
  color: $color-text;
  margin-bottom: $default-block-margin;
  position: relative;
  border-radius: 20px;
  // box-shadow: $box-shadow;

  &.loading {
    &:after {
      content: '';
      position: absolute;
      backdrop-filter: blur(3px);
      top: -1rem;
      left: -1rem;
      right: -1rem;
      bottom: -1rem;
    }
  }

  @include media-breakpoint-down(md) {
    display: flex;
    padding: 15px 10px 5px;
    flex-wrap: wrap;
  }

  + .pagination {
    margin-bottom: $default-margin - 5px;
  }

  &__row {
    position: relative;

    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-rows: 100% auto;
      grid-template-columns: minmax(60px, 8%) minmax(80px, 10%) minmax(125px, 10%) repeat(3, minmax(100px, 10%)) repeat(6, minmax(85px, 7%));
      .color-guide-row {
        font-size: 75%;
      }
    }

    @include media-breakpoint-up(xl) {
      .color-guide-row {
        font-size: 85%;
      }
    }

    @include media-breakpoint-down(md) {
      @include make-col(12);
      display: block;
      border-bottom: 2px solid $color-black-bg;
      margin: 0 10px 20px;
      padding: 0 10px 10px;
    }

    @include media-breakpoint-down(xs) {
      padding: 0 4px 10px;
      margin: 0 0 10px;
    }

    &.table-header {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &::before {
      content: '';
      width: calc(100% + 20px);
      position: absolute;
      left: -10px;
      top: 0;
      height: 100%;
      // background-color: $color-white;
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }

    .color-guide-row {
      &:not(.color-guide-row--head) {
        color: $color-text;
      }

      a {
        color: $color-text;
      }
    }

    &[href]:hover,
    &[href]:focus {
      text-decoration: none;

      &::before {
        opacity: 1;
      }

      .color-guide-row {
        border-color: $color-white;
        color: $color-black;
      }

      .icon {
        &--check-alt {
          @include icon(check-alt, $color-black, 14px, 10px);
        }

        &--cross {
          @include icon(close, $color-black, 13px, 13px);
        }
      }
    }
  }
}

.color-guide-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 8px;
  border-left: 2px solid $color-black-bg;
  border-bottom: 2px solid $color-black-bg;
  font-size: 15px;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all .3s ease-in-out;

  @include media-breakpoint-down(lg) {
    padding: 8px 6px;
  }

  @include media-breakpoint-down(md) {
    border: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100% auto;
  }

  &::before {
    content: attr(data-label);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: $text-colorer;
    display: none;
    padding-right: 10px;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &:first-of-type {
    border-left: 0;

    &::before {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &--head {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: $text-colorer;
    display: block;
  }

  span {
    word-wrap: break-word;
  }

  &--center {
    span {
      display: block;
      margin: 0 auto;

      @include media-breakpoint-down(md) {
        margin: 0;
      }
    }
  }

  &__bg {
    width: 100%;
    min-height: 50px;
    height: 100%;
    display: block;
    outline: 1px solid #f5f5f5;

    @include media-breakpoint-down(md) {
      width: 100%;
      height: 60px;
      grid-column: 1/3;
    }
  }

  .info-icon {
    @include icon(question, $color-white, 10px, 10px);
    display: block;
    margin: 5px auto 0 auto;
    width: 18px;
    height: 18px;
    background-color: $color-black;
    border-radius: 50%;

    .legacy-tooltip {
      color: $color-white;
      visibility: hidden;
      transition: visibility 0.25s;
    }

    &:hover {
      .legacy-tooltip {
        visibility: visible;
      }
    }
  }

  .tooltip {
    $self: &;
    cursor: pointer;
    position: relative;
    display: inline-block;
    z-index: 9;
    width: 22px;
    min-height: 25px;
    visibility: hidden;
    background-color: unset;

    @include media-breakpoint-down(lg) {
      width: 18px;
      min-height: 18px;
    }

    &__text {
      width: 20vw;
      min-width: 320px;
      background-color: $color-black;
      text-align: left;
      padding: 20px;
      top: 54px;
      font-size: 15px;
      text-transform: none;
      line-height: 20px;
      font-weight: 400;
      transform: translateX(calc(-50% + 11px));
      position: absolute;
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        top: -9px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid $color-black;
      }

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.icon {
  display: inline-block;
  height: 40px;
  width: 40px;

  &--check-alt {
    @include icon(check-alt, $color-black, 14px, 10px);
    width: 14px;
    height: 10px;
  }

  &--cross {
    @include icon(close, $color-black, 13px, 13px);
    width: 13px;
    height: 13px;
  }
}

.filter {
  .wrapper-color-code {
    display: flex;

    > div {
      flex-grow: 1;
      margin: 0 10px 0 0;
    }

    .tooltip {
      min-width: 22px;
      position: relative;
      top: auto;
      right: auto;
      left: auto;
      margin: 11px 0 0 auto;

      @include media-breakpoint-down(lg) {
        margin-right: 7px;
      }
    }
  }
}

