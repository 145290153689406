.header {
    position: relative;

    &__content {
        padding: 20px;
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            height: 60px;
            justify-content: center;
            border: 1px solid $text-color;
            padding: 10px;
            margin: 20px;
            border-radius: 30px;
        }
    }

    &__logo {
        @include media-breakpoint-up(lg) {
            min-width: 300px;
        }

        .logo {
            display: block;
            position: relative;
            z-index: 11;

            img {
                display: block;
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;
    }

    &__btns {
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        @include media-breakpoint-up(lg) {
            min-width: 300px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        .btn {
            background-color: transparent;
            border: 1px solid $text-color;
            color: $text-color;

            &:hover,
            &:focus,
            &:active {
                border-color: darken($text-color, 10%);
            }
        }
    }

    .nav-toggle {
        position: absolute;
        top: 20px;
        left: 20px;
        display: none;
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        padding: 0;
        z-index: 10;

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
        }

        span,
        &::before,
        &::after {
            top: 0;
            position: relative;
            display: block;
            width: 20px;
            height: 2px;
            margin: 3px auto;
            content: '';
            background-color: $text-color;
            border-radius: 2px;
            backface-visibility: hidden;
        }

        span {
            transition: opacity .3s;
        }

        &::before,
        &::after {
            transition: top .3s .3s, transform .3s 0s;
        }

        &.active {
            span {
                opacity: 0;
            }

            &::before,
            &::after {
                transition: top .3s 0s, transform .3s .3s;
            }

            &::before {
                top: 8px;
                transform: rotate(45deg);
            }

            &::after {
                top: -8px;
                transform: rotate(-45deg);
            }
        }
    }

    .btn {
        &.search {
            @include icon(search, $text-color, 14px, 14px);
        }

        &.language {
            @include icon(globe, $text-color, 14px, 14px);
        }
    }
}

/*
body:has(.hero-main) {
    .header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;

        &__content {
            border-color: $white;
        }

        .nav-toggle {
            span,
            &::before,
            &::after {
                background-color: $color-white;
            }
        }

        &__btns {
            .btn {
                border: 1px solid $color-white;
                color: $color-white;

                &:hover,
                &:focus,
                &:active {
                    border-color: $color-white;
                    background-color: rgba($color-white, .3);
                }
            }
        }

        .btn {
            &.search {
                @include icon(search, $color-white, 14px, 14px);
            }

            &.language {
                @include icon(globe, $color-white, 14px, 14px);
            }
        }

        .logo {
            img {
                filter:  brightness(0) invert(1);
            }
        }
    }
}
*/
