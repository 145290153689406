.block {
  &--creative {
    padding: 80px 0 100px;
    background: $color-gray;

    @include media-breakpoint-down(md) {
      padding: 30px 0;
    }
  }
}

.creative {
  color: $text-color;
  padding: 0 20px;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  &__title {
    text-align: center;

    @include media-breakpoint-down(sm) {
      margin-bottom: 34px;
      margin-top: 40px;
    }
  }

  &__tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }

    &-item {
      margin: 0 35px 20px;
      cursor: pointer;
      color: $color-red;
      position: relative;

      @include media-breakpoint-down(sm) {
        margin: 0 11px 6px;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $color-red;
        border-radius: 2px;
        opacity: 0;
        transition: opacity .3s;
      }

      &:hover,
      &.active {
        &::after {
          opacity: 1;
        }
      }

      &.active {
        cursor: default;
      }
    }
  }

  &__tab {
    display: none;

    &.active {
      display: block;
    }
  }
}

// creative-grid
.creative-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: 56px;

  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 66px;
  }

  & + & {
    padding-top: 114px;

    @include media-breakpoint-down(sm) {
      padding-top: 30px;
    }
  }

  &__item {
    position: relative;
    
    img {
      position: relative;
      border-radius: 20px;
      width: 100%;
    }

    /*
    &:nth-child(1):not(.big) {
      transform: translate(-9%, -9%);

      @include media-breakpoint-down(xs) {
        transform: translate(0, -9%);
      }
    }

    &:nth-child(2):not(.big) {
      transform: translate(-9%, 17%);

      @include media-breakpoint-down(xs) {
        transform: translate(-17%, 17%);
      }
    }

    &:nth-child(3):not(.big) {
      transform: translate(8%, -9%);

      @include media-breakpoint-down(xs) {
        transform: translate(19%, -29%);
      }
    }

    &:nth-child(4):not(.big) {
      transform: translate(9%, 17%);

      @include media-breakpoint-down(xs) {
        transform: translate(0, 0);
      }
    }
    */

    &.big {
      grid-row: 1 / 3;
      grid-column: 3 / 5;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &--reverse & {
    &__item {
      &.big {
        grid-row: 1 / 3;
        grid-column: 1 / 3;
      }
    }
  }

.creative-grid__item  span {
    width: 100%;
    background: $color-red;
    display: block;
    padding: 6px 50px 10px 20px;
    position: relative;
    height: 54px;
    margin-top: -54px;
    border-radius: 0 0 20px 20px;
    font-size: 16px;
    line-height: 1.3;
    overflow: hidden;
  }
}

// .creative-grid__item:nth-child(1) span {
//   transform: translate(-9%, -128%);
// }

// .creative-grid__item:nth-child(2) span {
//   transform: translate(-9%, -48%);
// }

// .creative-grid__item:nth-child(3) span {
//   transform: translate(8%, -128%);
// }

// .creative-grid__item:nth-child(4) span {
//   transform: translate(9%, -48%);
// }

.creative-grid .big span {
  height: 40px;
  margin-top: -40px;
  line-height: 26px;
}

.big {
  position: relative;
}

.creative-grid__item img.grid-social-icon,
.big img.grid-social-icon {
  position: absolute;
  max-width: 30px;
  max-height: 30px;
}


.creative-grid__item img.grid-social-icon {
  bottom: 12px;
  right: 10px;
}

.big img.grid-social-icon {
  bottom: 5px;
  right: 10px;
}
