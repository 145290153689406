.panel {
  color: $color-white;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 200;
  background-color: $color-red;
  border-radius: 0 0 0 20px;
  width: 842px;
  transition: right .7s;

  @include media-breakpoint-down(md) {
    border-radius: 0;
    width: 100%;
    bottom: 0;
    display: flex;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    align-items: center;
    z-index: 5;

    &-text {
      font-size: 14px;
      font-weight: 500;
      margin-right: 20px;
      color: $color-white;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__wrapper {
    padding: 20px 34px 10px;
    position: relative;
    z-index: 4;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__title {
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 50px;
    font-weight: 900;
    padding-right: 40px;
  }

  .btn--close.btn--white {
    &::after,
    &::before {
      background-color: darken($color-red, 10%);
    }
  }

  .search {
    padding: 8px 60px 0 0;

    @include media-breakpoint-down(md) {
      padding-right: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .form-element-suggestions__content {
      margin-top: 20px;

      @include media-breakpoint-down(md) {
        flex: 1;
        overflow: auto;
        padding-bottom: 20px;
      }
    }
  }

  .form-search {
    @include media-breakpoint-down(md) {
      margin-right: 60px;
    }
  }

  .find-product {
    &__content {
      width: 264px;
    }
  }

  &--find {
    background: transparent;
    width: auto;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip-path: polygon(0 0, 100% 0, 100% 73%, 20% 100%);
      background-color: $color-red;
      z-index: 1;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      background-color: $color-red;

      &::after {
        display: none;
      }
    }
  }

  &--find & {
    &__close {
      top: 20px;
      right: 20px;
    }

    &__wrapper {
      padding: 100px 260px 160px 215px;

      @include media-breakpoint-down(md) {
        padding: 100px 40px 40px;
        align-items: center;
        width: 100%;
      }
    }
  }

  &.active {
    right: 0;
  }
}

// Language popup

.popup {
  position: fixed;
  display: none;
  z-index: 99999;

  &.active {
      display: block;
  }

  &::before {
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      opacity: .9;
      background: $color-black;
  }

  &__wrapper {
      position: fixed;
      top: 50%;
      left: 50%;
      display: block;
      width: 700px;
      max-width: 80%;
      color: $color-white;
      padding: 30px 40px 10px;
      transform: translate(-50%, -50%);
      background-color: $color-black;
      border: 0 solid;
      text-align: center;
      max-height: 90%;
      overflow: auto;

      @include media-breakpoint-down(sm) {
          padding: 50px 20px;
      }

      h2 {
          text-align: center;
          line-height: 38px;
          font-size: 28px;
          text-transform: uppercase;
          color: $color-white;

          @include media-breakpoint-down(sm) {
              padding: 0;
          }
      }

      .btn {
          &--close {
              position: absolute;
              top: 10px;
              right: 10px;
          }
      }
  }
}