.hero-main {
  height: 460px;
  position: relative;
  display: flex;
  align-items: center;

  .is-h1 {
    color: $color-white;
    font-size: 72px;
    line-height: 80px;
    margin-bottom: $default-margin;
    font-weight: 400;
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;

    picture {
      height:100%;

      img {
        width: 100%;
        height:100%;
        object-fit: cover;
      }
    }

  }

  &__image {
    height: 100%;

    picture {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__video {
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    width: 100%;
    position: relative;
    text-align: center;
    z-index: 1;

    .btn {
        background-color: $color-white;
        color: $text-color;

        &:hover,
        &:focus,
        &:active {
            background-color: rgba($color-white, .9);
            color: $color-black;
        }
    }
  }

  &--video {
    &::before {
      opacity: .65;
    }
  }
}
