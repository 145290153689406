.block {
  &--selection {
    padding: 35px 0 50px;
    display: flex;
    align-items: center;
    min-height: 780px;

    .wrapper {
      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }
  }
}

.selection {
  color: $color-red;

  &__title {
    text-align: center;
    margin-bottom: 36px;
  }

  &__steps {
    padding-top: 30px;
    max-width: 830px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, minmax(260px, 1fr));
    grid-gap: 18px;

    @include media-breakpoint-down(md) {
      overflow: auto;
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px;
      max-width: 1000px;
    }

    @include media-breakpoint-down(sm) {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__step {
    display: flex;
    position: relative;
    padding-top: 80px;

    &:not(:last-child) {
      &::after {
        @include icon(step-arrow, $color-black, 92px, 80px);
        content: '';
        position: absolute;
        top: -36px;
        right: 0;
        width: 92px;
        height: 80px;
      }
    }

    &-head {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
    }

    &-count {
      background-color: $color-white;
      font-size: 32px;
      line-height: 55px;
      font-weight: 900;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      margin-right: 20px;
    }

    &-title {
      flex: 1;
    }

    .product-item {
      height: 340px;
      width: 100%;

      &__image {
        img {
          max-height: 220px;
        }
      }
    }
  }

  &__params {
    background-color: $color-white;
    border-radius: 20px;
    padding: 20px;
    color: $text-color;

    .surface {
      &__text {
        font-weight: 700;
        margin-bottom: 30px;
      }

      &__content {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 30px;
      }
    }
  }

  .select_all {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}