.informer {
  background-color: $color-red;
  padding: 12px 20px 20px;
  position: fixed;
  bottom: 40px;
  z-index: 50;
  width: 320px;
  box-shadow: 0 0 20px #00000033;
  transition: left .7s;

  @include media-breakpoint-down(md) {
    bottom: 65px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__title {
    margin-bottom: 10px;
  }

  .richtext {
    margin-bottom: 10px;
  }

  &--left {
    left: -110%;
    border-radius: 0 20px 20px 0;

    &.active {
      left: 0;
    }
  }

  &--right {
    right: -110%;
    border-radius: 20px 0 0 20px;

    &.active {
      right: 0;
    }
  }
}