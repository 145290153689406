.filter {
    &__title {
        margin-top: 20px;
    }
}

.highlights-overview {
    $self: &;

    &--row {
        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
        }

        #{ $self }__item {
            max-width: 33%;
            padding: 10px;

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }

            .highlight {
                display: block;

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                }

                &__image {
                    display: block;
                    max-width: 100%;
                    margin: 0;

                    img {
                        width: 100%;
                        border-radius: 20px 20px 0 0;
                    }
                }

                &__content {
                    padding: 15px;
                    max-width: 100%;
                    background-color: $color-white;
                    border-radius: 0 0 20px 20px;

                    .is-h3 {
                        color: $color-black;
                        margin: 0;
                        font-size: 16px;
                        line-height: 26px;
                    }

                    p {
                        color: $color-black;
                        margin: 0;

                        &.blog-article-link {
                            display: none;
                        }
                    }
                    .tags {
                        display: none;
                    }
                }
            }
        }
    }
}


html :not(.fp-enabled) #content > div > div.block--filter:first-of-type {
    padding: 115px 0 0 0;

    @include media-breakpoint-down(md) {
        padding: 40px 0 0 0;
    }
}

.block--article{

    .article__title {
        .is-h2,
        .is-h3,
        .is-h4,
        h2,
        h3,
        h4 {
            margin-top: 3rem;
        }
    }

    .info-publish {
        margin-top: -1rem;
        margin-bottom: 2rem;
        display: block;
    }

    .article-block__title {
        .is-h3,
        .is-h4,
        .is-h5,
        h3,
        h4,
        h5 {
            margin-top: 2rem;
            margin-bottom: 0.5rem;
        }
    }
}
