// Bootstrap settings
$container-max-widths: (
    'sm': 540px,
    'md': 720px,
    'lg': 960px,
    'xl': 1110px
) !default;

// Lightcase settings
$lightcase-custom: (
    'font-path': '/assets/fonts/',
    'breakpoint': 768px
) !default;

// Overall
$default-margin: 15px;
$default-block-margin: 64px;

$heading-font: 'Bebas Neue', sans-serif;
$text-font: 'Titillium Web', sans-serif;

$text-size: 16px;
$line-height: 24px;

$color-white: #fff;
$color-black: #000;

$text-color: #5d6770;

$color-red: #9e1c30;
$color-gray: #f0f0f0;
