@import 'bootstrap/scss/functions';
@import 'settings/fonts';
@import 'settings/vars';

@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/print';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/dropdown';

@import 'fullpage.js/dist/fullpage.min.css';
@import 'lightcase/src/scss/lightcase';
@import 'node_modules/swiper/swiper';
@import 'leaflet/dist/leaflet';

@import 'bootstrap-select/sass/bootstrap-select';

@import 'tools/mixin';

@import 'libs/fullpage/fullpage';
@import 'libs/lightcase/lightcase';
@import 'libs/maps/maps';
@import 'libs/swiper/swiper';
@import 'libs/tippy/tippy';

@import 'base/base';
@import 'base/heading';
@import 'base/list';
@import 'layout/header';
@import 'layout/mobile-nav.scss';
@import 'layout/footer';
@import 'components/bg-colors';
@import 'components/button';
@import 'components/form';
@import 'components/navigation';
@import 'components/pagination';
@import 'components/richtext';
@import 'components/social';
@import 'components/product-detail';
@import 'components/custom-select';
@import 'components/download';
@import 'components/filters';
@import 'components/shop-list';
@import 'components/informer';
@import 'components/panel';
@import 'components/surface';
@import 'components/tooltip';
@import 'components/gallery';
@import 'components/search';

@import 'contentblocks/about-block';
@import 'contentblocks/advantages-block';
@import 'contentblocks/catalog-block';
@import 'contentblocks/characteristics-block';
@import 'contentblocks/creative-block';
@import 'contentblocks/dealers-map';
@import 'contentblocks/hero-main-block';
@import 'contentblocks/highlight-block';
@import 'contentblocks/howto-block';
@import 'contentblocks/instruction-block';
@import 'contentblocks/product-summary-block';
@import 'contentblocks/products-block';
@import 'contentblocks/selection-block';
@import 'contentblocks/spraypaint-block';
@import 'contentblocks/title-block';
@import 'contentblocks/faq-block';
@import 'contentblocks/markets-block';
@import 'contentblocks/blog-overview-block';
@import 'contentblocks/where-to-buy-block';

@import 'contentblocks/find-product';
@import 'contentblocks/color-guide-block';
