.social-share {
    $self: &;
    position: absolute;
    top: 30px;
    right: 0;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .btn {
        &--social-share {
            font-weight: 600;
            color: $color-red;
            padding: 0;
            outline: none;
            display: flex;
            align-items: center;
            font-size: $text-size;
            line-height: $line-height;
            background-color: transparent;
            border: 0;
            min-height: 20px;

            &::before {
                @include icon(share, $color-red, 20px, 24px);
                display: inline-block;
                width: 20px;
                height: 24px;
                margin-right: 10px;
                content: '';
            }

            &:hover,
            &:focus {
                color: $color-red;
                text-decoration: none;
                background-color: transparent;

                &::before {
                    @include icon(share, $color-red, 20px, 24px);
                }
            }
        }
    }

    &--wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 300px;
        max-height: 0;
        padding: 0 10px;
        background-color: $color-red;
        overflow: hidden;
        z-index: 9998;
        transition: all .6s;
    }

    &.active {
        #{ $self }--wrapper {
            max-height: 290px;

            @include media-breakpoint-down(sm) {
                max-height: 480px;
            }
        }

        .list {
            &--social-share {
                li {
                    opacity: 1;
                }

                li:nth-child(1) {
                    transition-delay: .15s;
                }

                li:nth-child(2) {
                    transition-delay: .20s;
                }

                li:nth-child(3) {
                    transition-delay: .25s;
                }

                li:nth-child(4) {
                    transition-delay: .30s;
                }

                li:nth-child(5) {
                    transition-delay: .35s;
                }

                li:nth-child(6) {
                    transition-delay: .40s;
                }

                li:nth-child(7) {
                    transition-delay: .45s;
                }
            }
        }
    }

    h2 {
        position: relative;
        display: block;
        padding: 10px 10px 10px 50px;
        margin: 0 -10px 10px;
        color: $color-white;
        font-size: 16px;
        line-height: 30px;
        font-weight: 600;
        text-transform: uppercase;
        background-color: $color-red;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        &::before {
            @include icon(share, $color-white, 20px, 20px);
            position: absolute;
            top: 10px;
            left: 10px;
            display: block;
            width: 30px;
            height: 30px;
            content: '';
        }
    }

    .list {
        &--social-share {
            li {
                opacity: 0;
                transition: all .25s;
            }
        }
    }

    input {
        width: 100%;
        height: 40px;
        color: $color-white;
        font-family: $text-font;
        font-size: 14px;
        line-height: 22px;
        padding: 9px 20px;
        margin: 0 0 10px;
        background-color: $color-red;
        border: 2px solid $color-white;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .btn {
        &--close {
            @include icon(close, $color-white, 20px, 20px);
            position: absolute;
            top: 5px;
            right: 5px;
            display: block;
            width: 40px;
            height: 40px;
            font-size: 0;
            padding: 0;
            border: 0;
            background-color: transparent;

            &::after {
                display: none;
            }

            &:hover,
            &:focus {
                @include icon(close, $color-red, 20px, 20px);
                background-color: transparent;
            }
        }
    }
}
